var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("offline", {
        on: { "detected-condition": _vm.handleConnectivityChange },
      }),
      _c("notifications", {
        attrs: { position: "top center", group: "app", max: 1 },
      }),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }