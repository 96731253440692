/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: "/dashboard",
    // Relative to /src/views
    view: "Dashboard"
  },
  {
    path: "/user-profile",
    name: "Usuarios",
    view: "userProfile"
  },
  {
    path: "/roles",
    name: "Roles",
    view: "roles"
  },
  {
    path: "/active-authorizations",
    name: "active autorizations",
    view: "activeAuthorizations/activeAuthorizations"
  },
  {
    path: "/pendings",
    name: "Pendientes",
    view: "pending/Pending"
  },
  {
    path: "/providers",
    name: "Prestadores",
    view: "provider/Provider"
  },
  {
    path: "/load",
    name: "LoadData",
    view: "Load/LoadData"
  },
  {
    path: "/proccess",
    name: "Process",
    view: "Proccess/Proccess"
  },
  {
    path: "/table-list",
    name: "Table List",
    view: "TableList"
  },
  {
    path: "/typography",
    view: "Typography"
  },
  {
    path: "/icons",
    view: "Icons"
  },
  {
    path: "/maps",
    view: "Maps"
  },
  {
    path: "/notifications",
    view: "Notifications"
  },
  {
    path: "/upgrade",
    name: "Upgrade to PRO",
    view: "Upgrade"
  }
];
