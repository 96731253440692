<template >
  <v-toolbar id="core-toolbar" flat prominent style="background: #eee;" :mini-variant="mini">
    <v-toolbar-items :mini-variant="mini">
      <v-flex fixed align-center layout py-2>
        <div class="v-toolbar-title">
          <v-toolbar-title class="primary--text font-weight-light">
            <v-btn color="primary" class="v-btn--simple" dark icon @click.stop="mini = !mini" v-on:click="onClickBtn">
              <v-icon medium v-if="!mini">mdi-chevron-left</v-icon>
              <v-icon medium v-else>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar-title>
        </div>

        <v-menu bottom left content-class="dropdown-menu" offset-y transition="slide-y-transition">
          <template v-ripple slot="activator" class="toolbar-items" to="/notifications">
            <v-badge color="error" overlap>
              <template slot="badge">{{ notifications.length }}</template>
              <v-icon color="primary">mdi-bell</v-icon>
            </v-badge>
          </template>

          <v-card>
            <v-list dense>
              <v-list-tile
                v-for="notification in notifications"
                :key="notification.id"
                @click="onClick"
              >
                <v-list-tile-title
                  @click="reviewed(notification)"
                  v-text="notification.notification"
                />
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>

        <v-menu bottom left content-class="dropdown-menu" offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on }">
            <v-btn dark icon v-on="on">
              <v-icon color="primary">mdi-account</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list dense>
              <v-list-tile>
                <v-list-tile-title>
                  <router-link to="/user-profile">
                    <v-list-tile-title>{{ $t('my-profile') }}</v-list-tile-title>
                  </router-link>
                </v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="goOut">
                <v-list-tile-title>{{ $t('logout') }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>
      </v-flex>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import io from 'socket.io-client'
import { StorageService } from "../../services/StorageService.js";
import ApiService from "../../services/ApiService";

export default {
  data: () => ({
    socket: null,
    notifications: [],
    title: null,
    responsive: false,
    responsiveInput: false,
    mini: false
  }),

  watch: {
    $route(val) {
      this.title = val.name;
    }
  },

  // mounted() {
  //   this.socket = io(ApiService.getHostAndPort());
  //   this.socket.on("NOTIFICATION", data => {
  //     const user = StorageService.getUserData();
  //     if (!user.providerId || user.providerId === data.providerId) {
  //       this.notifications.push(data);
  //     }
  //   });

  //   this.socket.on("NOTIFICATION_DELETE", data => {
  //     const idOrder = data.id;
  //     const idProvider = data.providerId;
  //     const notificationIndex = this.notifications.findIndex(
  //       so => so.id === idOrder && so.providerId === idProvider
  //     );
  //     if (notificationIndex >= 0) {
  //       this.notifications.splice(notificationIndex, 1);
  //     }
  //   });
  //   this.onResponsiveInverted();
  //   window.addEventListener("resize", this.onResponsiveInverted);
  // },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },

  methods: {
    getUserName() {
      const user = StorageService.getUserData();
      this.user = user.name;
    },
    reviewed(notification) {
      this.socket.emit('REMOVE_NOTIFICATION', notification)
    },
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    ...mapActions("auth", ["logout"]),
    onClickBtn() {
      this.setDrawer(!this.$store.state.app.drawer);
    },
    onClick() {
      //
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
        this.responsiveInput = false;
      } else {
        this.responsive = false;
        this.responsiveInput = true;
      }
    },
    goOut() {
      this.logout().then(() => {
        this.$router.push("/login");
      });
    }
  }
};
</script>

<style>
#core-toolbar a {
  text-decoration: none;
}
</style>
