export default {
	data() {
		return {
			menuDelivery: false,
			data: {
				date: '',
				stage: '',
				status: ''
			},
			stage: [{
				id: '1',
				name: 'Direccionamiento'
			},
			{
				id: '2',
				name: 'Programada'
			},
			{
				id: '3',
				name: 'Entrega'
			},
			{
				id: '4',
				name: 'Reporte de entrega'
			}
			],
			status: [{
				id: '1',
				name: "Activo"
			}, {
				id: '2',
				name: "Anulado"
			},
			{
				id: '3',
				name: "Procesado"
			}]
		}
	},
	methods: {
		search (){
			this.$emit('search', this.data)
		}
	},
}