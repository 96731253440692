// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";

// Components
import "./components";

// Plugins
import "./plugins";

// Sync router with store
import { sync } from "vuex-router-sync";

// Notifications component
import Notifications from "vue-notification";

// Application imports
import App from "./App";
import i18n from "@/i18n";
import router from "@/router";
import store from "@/store";
import VueLodash from "vue-lodash";

import ApiService from "./services/ApiService";
import { TokenService } from "./services/StorageService";

import money from "v-money";
import VueBarcodeScanner from "vue-barcode-scanner";
import formatMoney from './plugins/formatMoney';

// Sync store with router
sync(store, router);

Vue.use(VueLodash, { name: "lodash" });

ApiService.init(process.env.VUE_APP_ROOT_API);

Vue.config.productionTip = false;

Vue.use(Notifications, { max: 1 });

Vue.use(formatMoney, formatMoney)

const accessToken = TokenService.getToken();

if (accessToken) {
    ApiService.setHeader();
}

Vue.use(money, { precision: 2 });

Vue.mixin({
    data: function() {
        return {
            get globalrowsPerPageItems() {
                return [5, 10, 15, 20]
            }
        }
    }
})

let options = {
    sound: false, // default is false
    // soundSrc: "/static/sound.wav", // default is blank
    sensitivity: 300, // default is 100
    requiredAttr: false, // default is false
    controlSequenceKeys: ["NumLock", "Clear"], // default is null
    callbackAfterTimeout: true // default is false
};

Vue.use(VueBarcodeScanner, options);

/* eslint-disable no-new */
new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount("#app");