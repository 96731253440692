var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    _vm._g(
      _vm._b(
        { staticClass: "v-card--material-chart" },
        "material-card",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("chartist", {
        attrs: {
          slot: "header",
          data: _vm.data,
          "event-handlers": _vm.eventHandlers,
          options: _vm.options,
          ratio: _vm.ratio,
          "responsive-options": _vm.responsiveOptions,
          type: _vm.type,
        },
        slot: "header",
      }),
      _vm._t("default"),
      _vm._t("actions", null, { slot: "actions" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }