var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    _vm._g(
      _vm._b(
        { staticClass: "v-card--material-stats" },
        "material-card",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "v-card",
        {
          staticClass: "pa-4",
          class: `elevation-${_vm.elevation}`,
          attrs: { slot: "offset", color: _vm.color, dark: "" },
          slot: "offset",
        },
        [
          _c("v-icon", { attrs: { size: "40" } }, [
            _vm._v("\n      " + _vm._s(_vm.icon) + "\n    "),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "text-xs-right" }, [
        _c("p", {
          staticClass: "category grey--text font-weight-light",
          domProps: { textContent: _vm._s(_vm.title) },
        }),
        _c("h3", { staticClass: "title display-1 font-weight-light" }, [
          _vm._v("\n      " + _vm._s(_vm.value) + " "),
          _c("small", [_vm._v(_vm._s(_vm.smallValue))]),
        ]),
      ]),
      _c(
        "template",
        { slot: "actions" },
        [
          _c(
            "v-icon",
            {
              staticClass: "mr-2",
              attrs: { color: _vm.subIconColor, size: "20" },
            },
            [_vm._v("\n      " + _vm._s(_vm.subIcon) + "\n    ")]
          ),
          _c("span", {
            staticClass: "caption font-weight-light",
            class: _vm.subTextColor,
            domProps: { textContent: _vm._s(_vm.subText) },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }