var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    {
      staticStyle: { background: "#eee" },
      attrs: {
        id: "core-toolbar",
        flat: "",
        prominent: "",
        "mini-variant": _vm.mini,
      },
    },
    [
      _c(
        "v-toolbar-items",
        { attrs: { "mini-variant": _vm.mini } },
        [
          _c(
            "v-flex",
            {
              attrs: { fixed: "", "align-center": "", layout: "", "py-2": "" },
            },
            [
              _c(
                "div",
                { staticClass: "v-toolbar-title" },
                [
                  _c(
                    "v-toolbar-title",
                    { staticClass: "primary--text font-weight-light" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "v-btn--simple",
                          attrs: { color: "primary", dark: "", icon: "" },
                          on: {
                            click: [
                              function ($event) {
                                $event.stopPropagation()
                                _vm.mini = !_vm.mini
                              },
                              _vm.onClickBtn,
                            ],
                          },
                        },
                        [
                          !_vm.mini
                            ? _c("v-icon", { attrs: { medium: "" } }, [
                                _vm._v("mdi-chevron-left"),
                              ])
                            : _c("v-icon", { attrs: { medium: "" } }, [
                                _vm._v("mdi-chevron-right"),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: {
                    bottom: "",
                    left: "",
                    "content-class": "dropdown-menu",
                    "offset-y": "",
                    transition: "slide-y-transition",
                  },
                },
                [
                  _c(
                    "template",
                    {
                      directives: [{ name: "ripple", rawName: "v-ripple" }],
                      staticClass: "toolbar-items",
                      attrs: { to: "/notifications" },
                      slot: "activator",
                    },
                    [
                      _c(
                        "v-badge",
                        { attrs: { color: "error", overlap: "" } },
                        [
                          _c("template", { slot: "badge" }, [
                            _vm._v(_vm._s(_vm.notifications.length)),
                          ]),
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v("mdi-bell"),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        _vm._l(_vm.notifications, function (notification) {
                          return _c(
                            "v-list-tile",
                            {
                              key: notification.id,
                              on: { click: _vm.onClick },
                            },
                            [
                              _c("v-list-tile-title", {
                                domProps: {
                                  textContent: _vm._s(
                                    notification.notification
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.reviewed(notification)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "v-menu",
                {
                  attrs: {
                    bottom: "",
                    left: "",
                    "content-class": "dropdown-menu",
                    "offset-y": "",
                    transition: "slide-y-transition",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g({ attrs: { dark: "", icon: "" } }, on),
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-account"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-title",
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/user-profile" } },
                                    [
                                      _c("v-list-tile-title", [
                                        _vm._v(_vm._s(_vm.$t("my-profile"))),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-tile",
                            { on: { click: _vm.goOut } },
                            [
                              _c("v-list-tile-title", [
                                _vm._v(_vm._s(_vm.$t("logout"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }