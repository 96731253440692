var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        bottom: "",
        left: "",
        "min-width": "300",
        "max-width": "300",
        "nudge-left": "12",
        "offset-x": "",
        transition: "slide-y-transition",
      },
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "elevation-0",
          staticStyle: { top: "96px" },
          attrs: {
            slot: "activator",
            color: "grey",
            dark: "",
            fab: "",
            fixed: "",
            top: "",
          },
          slot: "activator",
        },
        [_c("v-icon", [_vm._v("mdi-settings")])],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-container",
            { attrs: { "grid-list-xl": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-xs-center body-2 text-uppercase sidebar-filter",
                        },
                        [_vm._v("Sidebar Filters")]
                      ),
                      _c(
                        "v-layout",
                        { attrs: { "justify-center": "" } },
                        _vm._l(_vm.colors, function (c) {
                          return _c("v-avatar", {
                            key: c,
                            class: [
                              c === _vm.color
                                ? "color-active color-" + c
                                : "color-" + c,
                            ],
                            attrs: { size: "23" },
                            on: {
                              click: function ($event) {
                                return _vm.setColor(c)
                              },
                            },
                          })
                        }),
                        1
                      ),
                      _c("v-divider", { staticClass: "mt-3" }),
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-xs-center body-2 text-uppercase sidebar-filter",
                      },
                      [_vm._v("Images")]
                    ),
                  ]),
                  _vm._l(_vm.images, function (img) {
                    return _c(
                      "v-flex",
                      { key: img, attrs: { xs3: "" } },
                      [
                        _c("v-img", {
                          class: [_vm.image === img ? "image-active" : ""],
                          attrs: { src: img, height: "120" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.setImage(img)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            href: "https://www.creative-tim.com/product/vuetify-material-dashboard",
                            target: "_blank",
                            color: "success",
                            block: "",
                          },
                        },
                        [_vm._v("\n            Free Download\n          ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            href: "https://demos.creative-tim.com/vuetify-material-dashboard/documentation",
                            target: "_blank",
                            color: "primary",
                            block: "",
                          },
                        },
                        [_vm._v("\n            Documentation\n          ")]
                      ),
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "div",
                      { staticClass: "text-xs-center body-2 text-uppercase" },
                      [
                        _c("div", { staticClass: "sidebar-filter" }, [
                          _vm._v(
                            "\n              Thank You for Sharing!\n            "
                          ),
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-2 v-btn-facebook",
                                attrs: {
                                  color: "indigo",
                                  fab: "",
                                  icon: "",
                                  small: "",
                                  round: "",
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-facebook")])],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "v-btn-twitter",
                                attrs: {
                                  color: "cyan",
                                  fab: "",
                                  icon: "",
                                  small: "",
                                  round: "",
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-twitter")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }