var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-content",
    [
      _c(
        "div",
        { attrs: { id: "core-view" } },
        [
          _c(
            "v-fade-transition",
            { attrs: { mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
      _vm.$route.name !== "Maps" ? _c("core-footer") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }