var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    [
      _c(
        "material-card",
        [
          _c(
            "v-form",
            { ref: "branchForm" },
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { md4: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                lazy: "",
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "min-width": "290px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              readonly: "",
                                              label: "Fecha",
                                            },
                                            model: {
                                              value: _vm.data.date,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.data, "date", $$v)
                                              },
                                              expression: "data.date",
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.menuDelivery,
                                callback: function ($$v) {
                                  _vm.menuDelivery = $$v
                                },
                                expression: "menuDelivery",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { locale: "es", color: "primary" },
                                on: {
                                  input: function ($event) {
                                    _vm.menuDelivery = false
                                  },
                                },
                                model: {
                                  value: _vm.data.date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "date", $$v)
                                  },
                                  expression: "data.date",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { md4: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              "item-value": "id",
                              "item-text": "name",
                              items: _vm.stage,
                              label: "Etapa",
                            },
                            model: {
                              value: _vm.data.stage,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "stage", $$v)
                              },
                              expression: "data.stage",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { md4: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              "item-value": "id",
                              "item-text": "name",
                              items: _vm.status,
                              label: "Estado",
                            },
                            model: {
                              value: _vm.data.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "status", $$v)
                              },
                              expression: "data.status",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-4",
                          attrs: { color: "success", round: "" },
                        },
                        [_vm._v("Generar")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-4",
                          attrs: { color: "primary", round: "" },
                          on: { click: _vm.search },
                        },
                        [_vm._v("Buscar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }