var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-alert",
    _vm._g(
      _vm._b(
        {
          staticClass: "v-alert--notification",
          class: [`elevation-${_vm.elevation}`],
          attrs: { value: _vm.value },
        },
        "v-alert",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }