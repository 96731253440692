import ApiService from "./ApiService";
import { TokenService, StorageService } from "./StorageService";

class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.errorCode = errorCode;
    }
}

const AuthService = {
    /**
     * Login the user and store the access token to TokenService.
     *
     * @returns access_token
     * @throws AuthenticationError
     **/
    async login(username, password) {
        try {
            const options = [
            {
                id: 1,
                data:
                '{\\"name\\":\\"Autorizaciones activas\\",\\"component\\":\\"activeAuthorizations/activeAuthorizations\\",\\"path\\":\\"/active-authorizations\\",\\"icon\\":null}',
                parentId: null
            }
            ];

            const response = await ApiService.post('/auth', { username, password })
            TokenService.saveToken(response.data.token)
            StorageService.saveUserData(response.data.user)
            StorageService.saveOptions(options)
            ApiService.setHeader()

            // const parameter = (await parametersService.getParameterById(1)).data;
            // StorageService.saveIntegratedProvider(parameter ? +parameter.value : 0);

            return response.data.token
        } catch (error) {
            throw error
        }
    },

    logOut() {
        StorageService.clearStorage();
    }
};

export default AuthService;

export { AuthService as UserService, AuthenticationError };