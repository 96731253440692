import Vue from "vue";
import axios from "axios";
import { TokenService, StorageService } from "./StorageService";
import router from "../router/index";

const ApiService = {
    init(baseURL) {
        axios.defaults.baseURL = baseURL;
    },

    setHeader() {
        //axios.defaults.headers.common["authorization"] = TokenService.getToken();
        axios.defaults.headers.common["authorization"] = TokenService.getToken();
        const userData = StorageService.getUserData();
        axios.defaults.headers.common["user-id"] = userData ? userData.id : '';
        axios.defaults.headers.common["x-request-public-key"] = process.env.VUE_APP_CLIENT_KEY
    },

    getHostAndPort() {
        const baseUrl = axios.defaults.baseURL.split('://');
        return baseUrl.length === 1 ? baseUrl[0] : baseUrl[1];
    },

    removeHeader() {
        axios.defaults.headers.common = {};
    },

    get(resource) {
        return axios.get(resource);
    },

    post(resource, data) {
        return axios.post(resource, data);
    },

    put(resource, data) {
        return axios.put(resource, data);
    },

    delete(resource) {
        return axios.delete(resource);
    },

    patch(resource, data) {
        return axios.patch(resource, data);
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     **/
    customRequest(data) {
        return axios(data);
    }
};

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        let message = null;

        if (error.request) {
            const request = error.request;
            if (request.status === 403) {
                if (request.response === "Forbidden") {
                    message =
                        "Su sesión expiró, vuelva a ingresar su usuario y contraseña.";
                    router.push("/login");
                } else if (response.status === 500) {
                    message = "La aplicación no está respondiendo a las peticiones, por favor intente más tarde.";
                }
            } else if (error.request.status === 404) {
                message = "Servicio inválido, contacte al administrador del sistema";
            }
        }

        if (message) {
            Vue.notify({
                group: "app",
                type: "error",
                text: message
            });
        }

        return Promise.reject(error);
    }
);

export default ApiService;