var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-footer",
    { attrs: { id: "core-footer", absolute: "", height: "82" } },
    [
      _c("v-spacer"),
      _c("span", { staticClass: "font-weight-light copyright" }, [
        _vm._v("\n    ©\n    " + _vm._s(new Date().getFullYear()) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }