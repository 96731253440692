var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        id: "app-drawer",
        "mini-variant": _vm.mini,
        app: "",
        dark: "",
        floating: "",
        persistent: "",
        "mobile-break-point": "991",
        width: "260",
      },
      model: {
        value: _vm.inputValue,
        callback: function ($$v) {
          _vm.inputValue = $$v
        },
        expression: "inputValue",
      },
    },
    [
      _c(
        "v-img",
        { attrs: { gradient: _vm.sidebarOverlayGradiant, height: "100%" } },
        [
          _c(
            "v-layout",
            {
              staticClass: "fill-height",
              attrs: { tag: "v-list", column: "" },
            },
            [
              _c("v-list-tile", { staticClass: "mt-3" }),
              _c("v-treeview", {
                attrs: {
                  items: _vm.items,
                  "open-all": "",
                  "open-on-click": "",
                  "open.sync": "open",
                },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function (props) {
                      return [
                        props.item.to
                          ? _c(
                              "router-link",
                              {
                                class: {
                                  "is-active": _vm.subIsActive(props.item),
                                },
                                attrs: { to: props.item.to },
                              },
                              [
                                _c("v-icon", [_vm._v(_vm._s(props.item.icon))]),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(props.item.name) +
                                    "\n          "
                                ),
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(props.item.name))]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.tree,
                  callback: function ($$v) {
                    _vm.tree = $$v
                  },
                  expression: "tree",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }